<script setup lang="ts">
import { type ProductType } from 'configs';
import { ArrowRightIcon } from 'lucide-vue-next';
import { colors } from 'shared-components';
import {
  type AlgoliaFilterFacet,
  DesignType,
} from '#gql/default';
import { getSpacingClassesForBlok } from '@/utils/storyblok';
import type { ProductCarouselStoryblok } from '@/storyblok-types';
import type { FilterCategoryWithFacets } from '@/types/list-page';

withDefaults(defineProps<{
  blok: ProductCarouselStoryblok;
}>(), {  })

const GqlInstance = useGql();
const { locale } = useI18n();

const {
  getFilterScaffold,
  setFilterScaffold,
} = useFilterScaffold();

await setFilterScaffold();

const { getStoryblokUrl } = useStoryblokData();

const generateTopicFilters = (algoliaFilters: Omit<FilterCategoryWithFacets, 'productColor'>): AlgoliaFilterFacet[] => {
  const result: AlgoliaFilterFacet[] = [];

  Object.entries(algoliaFilters).forEach(([
    filterCategory,
    filterValues,
  ]) => {
    const matchingInitialFilters = getFilterScaffold.value.find((initialFilter) => initialFilter.key === filterCategory)?.filters;

    if (matchingInitialFilters) {
      filterValues.forEach((filterValue) => {
        if (matchingInitialFilters.some((filter) => filter.key === filterValue)) {
          result.push({
            key: filterCategory,
            value: [ filterValue ],
          });
          return;
        }

        matchingInitialFilters.forEach((filter) => {
          const subFilter = isNestedFilter(filter) && filter.subItems.find((subItem) => subItem.key === filterValue);
          if (subFilter) {
            const facetKey = `${filterCategory}.${filter.key}`;
            const existingResult = result.find((resultItem) => resultItem.key === facetKey);

            if (existingResult) {
              existingResult.value.push(filterValue);
            } else {
              result.push({
                key: facetKey,
                value: [ filterValue ],
              });
            }
          }
        });
      });
    };
  });

  return result;
};

const algoliaFilterFacets = computed<AlgoliaFilterFacet[]>(() => {
  const filters = {
    designColor: __props.blok.algoliaFilterDesignColor?.map(String) ?? [],
    gender: __props.blok.algoliaFilterGender?.map(String) ?? [],
    occasion: __props.blok.alogliaFilterOccasion?.map(String) ?? [],
    product: __props.blok.algoliaFilterProduct?.map(String) ?? [],
    recipient: __props.blok.algoliaFilterRecipient?.map(String) ?? [],
    room: __props.blok.alogliaFilterRoom?.map(String) ?? [],
    style: __props.blok.algoliaFilterStyle?.map(String) ?? [],
    technique: __props.blok.algoliaFilterTechnique?.map(String) ?? [],
    topic: __props.blok.algoliaFilterTopic?.map(String) ?? [],
  };

  return generateTopicFilters(filters);
});

const { data } = await useAsyncData(
  `productCarousel-${locale.value}-${__props.blok._uid}`,
  () => GqlInstance('Designs', {
    designType: __props.blok.preselectedDesignType
      ? __props.blok.preselectedDesignType.toUpperCase() as DesignType
      : DesignType.ALL,
    filterFacets: algoliaFilterFacets.value,
    localeCode: locale.value,
    nodesPerPage: 12,
    query: '',
    sort: __props.blok.preselectedSorting,
  }),
);

const isPurpleSectionBg = computed(() => __props.blok.backgroundColor?.value === colors.purple);

const spacingClasses = computed(() => getSpacingClassesForBlok(__props.blok));

const containerClass = computed(() => (__props.blok.containerSize === 'large'
  ? 'max-w-9xl'
  : 'max-w-5xl'));

const getActiveProductTypes = computed<ProductType[]>(() => __props.blok?.algoliaFilterProduct as ProductType[] || undefined);
</script>

<template>
  <div
    v-editable="blok"
    :class="[isPurpleSectionBg ? 'text-white' : 'text-dark', spacingClasses]"
    :style="{ backgroundColor: blok.backgroundColor.value }"
  >
    <div
      class="container relative overflow-hidden"
      :class="[containerClass]"
    >
      <div class="mb-4 flex flex-wrap items-end justify-between gap-4">
        <div>
          <h3
            v-if="blok.headline"
            class="mb-0 text-3xl font-black"
          >
            {{ blok.headline }}
          </h3>
          <p
            v-if="blok.subline"
            class="justify mb-0 mt-2 flex text-lg font-medium opacity-80"
          >
            {{ blok.subline }}
          </p>
        </div>

        <div
          v-if="blok.link"
          class="hidden md:block"
        >
          <Button
            as-child
            size="small"
            variant="outline"
          >
            <NuxtLink :to="getStoryblokUrl(blok.link)">
              {{ blok.linkText }}
              <ArrowRightIcon class="size-4" />
            </NuxtLink>
          </Button>
        </div>
      </div>

      <div class="-mx-2">
        <UiScroller
          button-style-dark
          never-hide-buttons
          class="product-carousel-scroller"
          position-button-left="left-8"
          position-button-right="right-8"
          :scroll-distance="290"
        >
          <template #scrollerContent>
            <div
              v-for="design in data?.designs?.nodes ?? []"
              :key="design.objectId"
              class="first mr-px flex w-72 shrink-0 grow-0 snap-start px-2 pb-8 pt-4"
            >
              <DesignTile
                show-product-type
                class="text-dark"
                :active-product-types="getActiveProductTypes"
                :design="design"
              />
            </div>
          </template>
        </UiScroller>
      </div>

      <div
        v-if="blok.link"
        class="flex justify-center md:hidden"
      >
        <Button
          as-child
          size="small"
          variant="outline"
        >
          <NuxtLink :to="getStoryblokUrl(blok.link)">
            {{ blok.linkText }}
            <ArrowRightIcon class="size-4" />
          </NuxtLink>
        </Button>
      </div>
    </div>
  </div>
</template>
